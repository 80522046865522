<template>
  <header class="flex flex-col ">
    <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-900 dark:border-gray-800 order-1 border-b">
      <div class="flex justify-between items-center">
        <div class="flex flex-shrink-0 justify-start items-center">
          <div class="flex mr-6">
            <img src="~/assets/wetix-logo-text-navy.svg" class="block h-8 w-auto" alt="WeTix">
            <LayoutAppSwitcher :active-app-name="props.appName as string" />
          </div>
        </div>

        <ul
          v-if="props.topLinks"
          class="hidden flex-col justify-center mt-0 w-full text-sm font-medium text-gray-500 md:flex-row dark:text-gray-400 md:flex"
        >
          <li v-for="link in props.topLinks" class="block border-b dark:border-gray-700 md:inline md:border-b-0">
            <NuxtLink
              :to="link.to"
              class="block py-3 px-4 rounded-lg hover:text-gray-900 dark:hover:text-white"
            >
              {{ link.name }}
            </NuxtLink>
          </li>
        </ul>

        <div class="flex flex-shrink-0 justify-between items-center ml-4 lg:order-2">
          <!-- UPGRADE BUTTON -->
          <LayoutHeaderConsoleUpgradeButton v-if="props.showUpgrade" />

          <!-- NOTIFICATIONS BUTTON -->
          <LayoutHeaderConsoleNotifications />

          <!-- USER PROFILE MENU BUTTON -->
          <LayoutHeaderConsoleAppsButton />

          <!-- USER PROFILE MENU BUTTON -->
          <LayoutHeaderConsoleProfileMenuButton />

          <!-- TOGGLE MENU BUTTON -->
          <button
            id="toggleMobileMenuButton"
            type="button"
            data-collapse-toggle="toggleMobileMenu"
            class="items-center p-2 text-gray-500 rounded-lg md:ml-2 md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>

    <nav
      id="toggleMobileMenu"
      class="hidden order-3 bg-white border-b border-gray-200 shadow-sm dark:bg-gray-900 md:block dark:border-gray-800 md:order-2"
    >
      <div class="py-3 px-4 lg:px-6">
        <div class="flex flex-col justify-between items-center md:flex-row">
          <ul
            class="flex flex-col order-2 mt-0 w-full text-sm font-medium bg-gray-50 rounded-lg border border-gray-200 md:flex-row md:order-1 md:bg-white dark:bg-gray-800 dark:border-gray-700 dark:md:bg-gray-900 md:rounded-none md:border-0"
          >
            <li v-for="link in subLinks" :key="link.to">
              <NuxtLink
                :to="link.to"
                class="block py-3 px-4 text-gray-500 rounded-lg dark:text-gray-400 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                aria-current-value="page"
                active-class="bg-gray-50 text-primary-600 hover:bg-white"
              >
                {{ link.name }}
              </NuxtLink>
            </li>
          </ul>

          <!-- SUB-NAV SEARCH -->
          <form
            v-if="props.showSearch"
            class="flex order-1 items-center mb-4 w-full md:ml-4 md:max-w-sm md:order-2 md:mb-0"
          >
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                id="simple-search"
                type="search"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Find anything"
                required
              >
            </div>
            <button
              type="submit"
              class="p-2.5 ml-2 text-sm font-medium text-white bg-primary-700 rounded-lg border border-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <svg
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <span class="sr-only">Search</span>
            </button>
          </form>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import {
  initAccordions,
  initCarousels,
  initCollapses,
  initDials,
  initDismisses,
  initDrawers,
  initDropdowns,
  initModals,
  initPopovers,
  initTabs,
  initTooltips
} from 'flowbite'

export interface NavItemProps {
    name?: string
    to?: string
}

// Props
const props = defineProps<{
    appName: String, // Used to display in the top bar and titles
    topLinks: NavItemProps[], // Array of objects with title & to properties for NuxtLinks in center of top header.
    subLinks: NavItemProps[], // Array of objects with title & to properties for NuxtLinks in sub-header.
    showSearch?: Boolean,
    showUpgrade?: Boolean,
}>()

// initialize components based on data attribute selectors
onMounted(() => {
  initAccordions()
  initCarousels()
  initCollapses()
  initDials()
  initDismisses()
  initDrawers()
  initDropdowns()
  initModals()
  initPopovers()
  initTabs()
  initTooltips()
})

</script>
